import { initializeApp } from "firebase/app";

const config = {
	apiKey: "AIzaSyBF-cXaCx3aw15S-ecJEncAGS49nkN93Ps",
	authDomain: "grant-app-75119.firebaseapp.com",
	databaseURL:
		"https://grant-app-live-1-1-0.europe-west1.firebasedatabase.app/",
	projectId: "grant-app-75119",
	storageBucket: "grant-live-1-1-0",
	messagingSenderId: "18112193895",
	appId: "1:18112193895:web:94b07b07b1bd1acef77bc2",
	measurementId: "G-4CQFETHDXB",
};

// const config = initializeApp(firebaseConfig);
export default config;

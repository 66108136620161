export const loadAnimation = async (animationName) => {
	try {
		switch (animationName) {
			case "confetti":
				return await import("../../assets/animation/confeti.json");
			default:
				throw new Error(`Animation "${animationName}" not found`);
		}
	} catch (error) {
		console.error(`Failed to load animation "${animationName}":`, error);
		throw error;
	}
};

import React, { createContext } from "react";
import { initializeApp } from "firebase/app";

import config from "../../config/firebase";

const FirebaseContext = createContext();

const app = initializeApp(config);

const FirebaseProvider = ({ children }) => {
	return (
		<FirebaseContext.Provider value={{ app }}>
			{children}
		</FirebaseContext.Provider>
	);
};

export { FirebaseContext, FirebaseProvider };

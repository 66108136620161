import React, { createContext, useRef, useState } from "react";

import Lottie from "react-lottie";

import { loadAnimation } from "./utils";

const VfxContext = createContext();

const VfxProvider = ({ children }) => {
	const [animationSource, setAnimationSource] = useState(null);

	const animRef = useRef(null);

	const defaultOptions = {
		loop: false,
		autoplay: true,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const playVfx = async (animationName) => {
		try {
			const source = await loadAnimation(animationName);
			setAnimationSource(source.default);
			setTimeout(() => {
				animRef.current?.play();
			}, 100); // Ensure the animation is loaded before playing
		} catch (error) {
			console.warn(
				`Animation "${animationName}" not found or failed to load`
			);
		}
	};

	return (
		<VfxContext.Provider value={{ playVfx }}>
			{children}
			{animationSource && (
				<div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center pointer-events-none">
					<Lottie
						options={{
							...defaultOptions,
							animationData: animationSource,
						}}
						eventListeners={[
							{
								eventName: "complete",
								callback: () => setAnimationSource(null),
							},
						]}
					/>
				</div>
			)}
		</VfxContext.Provider>
	);
};

export { VfxContext, VfxProvider };

import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { FirebaseProvider } from "./core/FirebaseProvider/FirebaseProvider";
import { AuthProvider } from "./core/AuthProvider/AuthProvider";
import { VfxProvider } from "./core/VfxProvider";

const Grantee = React.lazy(() => import("./pages/Grantee"));
const GranteeAccount = React.lazy(() => import("./pages/GranteeOnboard"));

const routes = createBrowserRouter([
	{
		path: "/grantee/:userCode/:success?",
		element: <Grantee />,
	},
	{
		path: "/grantee-account/:onboard?",
		element: <GranteeAccount />,
	},
	{
		path: "*",
		element: <div>Not Implemented</div>,
	},
]);

function App() {
	return (
		<VfxProvider>
			<FirebaseProvider>
				<AuthProvider>
					<React.Suspense
						fallback={() => (
							<div
								className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
								role="status"
							>
								<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
									Loading...
								</span>
							</div>
						)}
					>
						<RouterProvider router={routes} />
					</React.Suspense>
				</AuthProvider>
			</FirebaseProvider>
		</VfxProvider>
	);
}

export default App;

import { useContext, useCallback } from "react";
import { FirebaseContext } from "../core/FirebaseProvider/FirebaseProvider";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";

const useAuth = () => {
	const { app } = useContext(FirebaseContext);
	const auth = getAuth(app);

	const signAnonymous = useCallback(async () => {
		console.log("Doing a sign in");
		try {
			await signInAnonymously(auth);
		} catch (err) {
			console.error("Anonymous sign in error", err);
		}
	}, [auth]);

	const authStateChangedListener = (callback) =>
		onAuthStateChanged(auth, callback);

	return {
		auth,
		services: {
			signAnonymous,
			authStateChangedListener,
		},
	};
};

export default useAuth;

// AuthContext.js
import React, { createContext, useState, useCallback, useEffect } from "react";

import useAuth from "../../hooks/useAuth";
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

const AppStates = {
	init: 0,
	logged_in: 1,
	not_logged_in: -1,
	onboarding: 2,
	anonymous: 3,
};

const AuthProvider = ({ children }) => {
	const { auth, services } = useAuth();
	const [appState, setAppState] = useState(AppStates.init);
	const [user, setUser] = useState(null);

	const bootstrap = useCallback(
		async (u) => {
			console.log("[⚡] Auth Changed", u);
			if (u?.uid) {
				setUser({
					uid: u.uid,
				});
				setAppState(AppStates.anonymous);
			} else {
				setAppState(AppStates.not_logged_in);
			}
		},
		[setAppState]
	);

	useEffect(() => {
		console.log("State", appState);
		if (appState === AppStates.not_logged_in) {
			console.log("Trying to do a login");
			services.signAnonymous(); // Login anonymously, phase 2 will have real users here
		}
	}, [services, appState]);

	// Simulate authentication state change
	useEffect(() => {
		const subscriber = services.authStateChangedListener(bootstrap);
		return subscriber; // unsubscribe on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider value={{ AppStates, appState, user }}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider, AppStates };
